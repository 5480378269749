import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGamepadAlt, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { useRandomNextCard } from "../hooks/dilemmas"

const HomePage = () => {
  const [nextCard] = useRandomNextCard(null)

  return (
    <Layout>
      <div className="hidden md:block md:w-4/12 md:mx-auto lg:w-auto lg:absolute lg:top-0 lg:right-0 lg:bottom-0 mt-16 lg:left-half pointer-events-none">
        <img src={"/images/explosion.svg"} alt="" className="lg:h-full" />
      </div>
      <div className="container mx-auto px-8">
        <div className="lg:w-5/12 py-12 lg:py-24 xl:py-32 flex flex-col items-center text-center lg:items-start lg:text-left">
          <div className="w-9/12 lg:w-auto">
            <img src={"/images/conversation-enders.svg"} alt="" className="mx-auto"/>
          </div>
          <div className="w-10/12 lg:w-auto mt-6">
            <img src={"/images/developer-dilemmas.svg"} alt="" className="mx-auto"/>
          </div>
          <p className="text-lg lg:text-2xl mt-10 lg:mt-12">A simple & fun online game for developers. Play it by yourself, or socialize while the world is in this crazy lockdown state. Start a video call, open a dilemma, pick a side and fight, discuss or laugh about it with other developers.</p>
          <div className="mt-8 lg:mt-12">
            <Link to="/howto" className="c-button c-button--gray my-4 mr-4 lg:mr-8">
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              How to play
            </Link>
            <Link to={nextCard.path} className="c-button c-button--white my-4 ">
              <FontAwesomeIcon icon={faGamepadAlt} className="mr-2" />
              Get started
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
